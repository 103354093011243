












import {Component, Vue} from 'vue-property-decorator'
import AppFooter from '@/components/AppFooter.vue'
import NavbarMenu from '@/components/NavbarMenu.vue'
import TermsAndConditionsHeader from '@/components/termsAndConditions/TermsAndConditionsHeader.vue'
import TermsAndConditionsContent from '@/components/termsAndConditions/TermsAndConditionsContent.vue'
import {MetaInfo} from 'vue-meta'

@Component({
  components: {
    AppFooter,
    NavbarMenu,
    TermsAndConditionsHeader,
    TermsAndConditionsContent,
  },
})
export default class TermsAndConditionsView extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.termsAndConditions'),
    }
  }

  mounted() {
    window.scrollTo(0, 0)
  }
}
